import React, { createContext, useState, useEffect } from "react";

// Create a context
export const DataContext = createContext();

// Create a provider component
export const DataProvider = ({ children }) => {
  const [profiles, setProfiles] = useState(() => {
    // Initialize data from localStorage if available
    const storedData = localStorage.getItem("data");
    return storedData ? JSON.parse(storedData) : [];
  });
  const [accessToken, setAccessToken] = useState(null);

  const getProfiles = async () => {
    console.log("get profiles function");
    try {
      const response = await fetch("https://certc.in:8443/api/getprofiles", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          accessToken: accessToken,
        }),
      });
      const data = await response.json();
      if (response.status === 404) {
        alert(data.message);
      } else if (response.status === 200) {
        if (data.data.length > 0) {
          setProfiles(data.data);
          console.log(data.data);
        }
      }
    } catch (e) {
      console.log(e);
      alert(e);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setAccessToken(token);
    }
  }, []);

  useEffect(() => {
    getProfiles();
  }, [accessToken]);

  return (
    <DataContext.Provider value={{ profiles, setProfiles }}>
      {children}
    </DataContext.Provider>
  );
};
