import WhatsappProfile from "./Profile/profile";
import Auth from "./Auth/auth";
import SendMedia from "./Sendmedia/sendmedia.jsx";
import "./whatsapp.css";
import { Link, Routes, Route } from "react-router-dom";
import Report from "./Report/report";
import { DataProvider } from "../../context/context.js";

const WhatsApp = () => {
  return (
    <DataProvider>
      <div className="whatsapp">
        {/* Left Navigation Bar */}
        <div className="leftNavBar">
          <Link to="oauth">
            <div className="addAccountBtn">Add Account</div>
          </Link>

          <div className="navbar">
            <h5>Features</h5>
            <ul>
              <Link to="profiles">
                <li>Profile</li>
              </Link>
              <Link to="sendmedia">
                <li>Send Message or Media</li>
              </Link>
            </ul>
          </div>
        </div>

        {/* Main Content Area */}
        <div className="rightContent">
          <Routes>
            <Route path="/" element={<Report />} />
            <Route path="profiles" element={<WhatsappProfile />} />
            <Route path="oauth" element={<Auth />} />
            <Route path="sendmedia" element={<SendMedia />} />
          </Routes>
        </div>
      </div>
    </DataProvider>
  );
};

export default WhatsApp;
