import { Link } from "react-router-dom";
import "./home.css";
const Home = () => {
  return (
    <div>
      <div className="nav-bar">
        <div>
          <img src="" alt="logo" />
        </div>
        <ul>
          <li>Home</li>
          <li>Features</li>
          <li>Pricing</li>
          <li>FAQs</li>
        </ul>
        <div className="buttons">
          <div className="btns">
            <Link to={"signup"} className="react_link">
              Sign Up
            </Link>
          </div>
          <div className="btns">
            <Link to={"login"} className="react_link">
              Log In
            </Link>
          </div>
        </div>
      </div>
      <div className="MainCon">
        <h2>WelCome to WhatsApp Crm</h2>
      </div>
    </div>
  );
};

export default Home;
