import "./auth.css";
import { useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { DataContext } from "../../../context/context";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Replace with your server's URL
const SOCKET_SERVER_URL = "https://certc.in:8443";

const Auth = () => {
  const location = useLocation();
  let data = location.state;
  const [accessToken, setAccessToken] = useState(null); // Set initial value to null
  const [instanceId, setInstanceId] = useState(null);
  const [qrCodeSrc, setQrCodeSrc] = useState(null);
  const [loading, setLoading] = useState(false); // New state for loading spinner
  const [name, setName] = useState(null);
  const [getBtnText, setGetButtonText] = useState("Get Instance and Qr Code");
  const { profiles } = useContext(DataContext);
  const [tempProfiles, setTempProfiles] = useState([]);

  const startSocket = (userId) => {
    const socketInstance = io(SOCKET_SERVER_URL);

    // Register the user on the server with their userId
    socketInstance.emit("registerUser", userId);
    // Listen for incoming messages from the server
    socketInstance.on("receiveqrcode", (msg) => {
      setQrCodeSrc(msg.qrcode);
      setInstanceId(msg.instanceId);
    });

    socketInstance.on("clientallready", (msg) => {
      setName(`Welcome Again : ${msg.name}`);
      const tempProfile5 = tempProfiles.filter(
        (item) => String(item.phoneNumber) !== String(msg.phoneNumber)
      );
      console.log(tempProfile5);
      setTempProfiles(tempProfile5);
    });

    socketInstance.on("clientready", (msg) => {
      setName(`You are connected as : ${msg.name}`);
    });
  };

  // // Function to fetch instance ID using the access token
  const fetchInstanceId = async (token) => {
    setLoading(true); // Show spinner while sending data
    startSocket(token);
    try {
      await fetch("https://certc.in:8443/api/getinstance", {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set content type for JSON
        },
        body: JSON.stringify({ accessToken: token }),
      });
      setLoading(false);
    } catch (error) {
      console.log("Error fetching instance ID:", error);
      setLoading(false);
    }
  };

  const reloginhandle = async (e) => {
    const tempInstanceId = e.target.value;
    const tempProfile3 = tempProfiles.filter(
      (item) => item.instanceId === tempInstanceId
    );
    if (tempProfile3[0].isLogged === false) {
      if (accessToken) {
        fetchInstanceId(accessToken);
        const tempProfile4 = tempProfiles.filter(
          (item) => item.instanceId !== tempInstanceId
        );
        setTempProfiles(tempProfile4); // Call fetchInstanceId when accessToken is available
      } else {
        alert("Access Token is not present");
      }
    } else {
      try {
        const response = await fetch("https://certc.in:8443/api/reloginwa", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            accessToken: accessToken,
            instanceId: tempInstanceId,
          }),
        });
        if (response.status === 200) {
          toast.info("Log in successfully", {
            position: "top-right",
            autoClose: 1000, // Close after 3 seconds
          });
          const tempProfile2 = tempProfiles.filter(
            (item) => item.instanceId !== tempInstanceId
          );
          console.log(tempProfile2);
          setTempProfiles(tempProfile2);
        } else {
          alert("Internal Server Error");
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleOnClick = () => {
    if (accessToken) {
      fetchInstanceId(accessToken); // Call fetchInstanceId when accessToken is available
    } else {
      alert("Access Token is not present");
    }
  };

  // useEffect to fetch access token when the component mounts
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setAccessToken(token);
    }
  }, []);

  useEffect(() => {
    const fromProfile = sessionStorage.getItem("fromProfile");
    if (fromProfile) {
      if (accessToken) {
        fetchInstanceId(accessToken); // Call fetchInstanceId when accessToken is available
        if (data) {
          const tempProfile4 = tempProfiles.filter(
            (item) => item.instanceId !== data
          );
          setTempProfiles(tempProfile4); // Call fetchInstanceId when accessToken is available
        }
        sessionStorage.removeItem("fromProfile"); // Clear the flag to prevent future calls on reload
      }
    }
  }, [accessToken]);

  useEffect(() => {
    if (qrCodeSrc) {
      setLoading(false);
    }
  }, [qrCodeSrc]);

  useEffect(() => {
    if (profiles.length > 0) {
      const temp = profiles.filter(
        (item) => item.logoutFromUi === true || item.isLogged === false
      );
      setTempProfiles(temp);
      console.log(temp);
    }
  }, [profiles]);

  useEffect(() => {
    if (name) {
      setQrCodeSrc(null);
      setGetButtonText("Get New Instance Id");
    }
  }, [name]);

  return (
    <div className="authCon">
      <ToastContainer />
      {loading && <div className="spinner"></div>} {/* Loading spinner */}
      {!loading && (
        <div className="authCard">
          <h2>Add WhatsApp Profile</h2>

          <div>
            <span>Your Access Token:</span>
            <div className="accessToken">
              {accessToken ? accessToken : "Not available"}
            </div>
          </div>

          <div>
            <span>Instance Id:</span>
            <div className="instanceId">
              {instanceId ? instanceId : "Not fetched yet"}
            </div>
          </div>

          {name && (
            <div>
              <span> {name}</span>
            </div>
          )}

          <button onClick={handleOnClick}>{getBtnText}</button>

          {qrCodeSrc && (
            <div>
              <img src={qrCodeSrc} alt="QR Code" />
            </div>
          )}
          {tempProfiles.length > 0 && (
            <div className="reloginMainCom">
              {tempProfiles.map((tempProfile) => (
                <div className="reloginDetailsCon">
                  <div>
                    {tempProfile.name}
                    {"   "}
                    {tempProfile.phoneNumber}
                  </div>
                  <div className="reloginBtnCon">
                    <button
                      onClick={reloginhandle}
                      value={tempProfile.instanceId}
                    >
                      re-Login
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Auth;
