import { useState } from "react";
import "./signup.css";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false); // New state for loading spinner
  const navigate = useNavigate();

  const handleCheckBox = (e) => {
    setIsChecked(e.target.checked);
  };

  const postSignUp = async () => {
    if (!name || !username || !password) {
      alert("All fields are required!");
      return;
    }

    setLoading(true); // Show spinner while sending data

    const response = await fetch("https://certc.in:8443/api/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        username: username,
        password: password,
      }),
    });

    setLoading(false); // Hide spinner after response

    const data = await response.json();
    if (response.status === 200) {
      console.log(data);
      const Token = data.user.accessToken;
      if (isChecked === true) {
        // localStorage.clear();
        localStorage.setItem("token", Token);
      } else {
        localStorage.setItem("token", Token);
      }
      navigate("/whatsapp");
    }
  };

  return (
    <div className="signup">
      {loading && <div className="spinner"></div>} {/* Loading spinner */}
      {!loading && (
        <>
          <div className="label">
            <h3>Create an account</h3>
          </div>
          <input
            type="text"
            id="name"
            placeholder="Enter Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="text"
            id="username"
            placeholder="Enter Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            id="password"
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input type="button" value="Submit" onClick={postSignUp} />
          <div className="rememberMeCon">
            <input
              type="checkbox"
              name="remember me"
              id="checkbox"
              onChange={handleCheckBox}
            />
            <div>Remember Me</div>
          </div>
        </>
      )}
    </div>
  );
};

export default SignUp;
