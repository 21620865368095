import { DataContext } from "../../../context/context";
import styles from "./sendmedia.module.css";
import { useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SendMedia = () => {
  const [isProfile, setIsProfile] = useState(null);
  const [instanceId, setInstanceId] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [tempProfiles, setTempProfiles] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [name, setName] = useState(null);
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null); // New state for image URL
  const [inputType, setInputType] = useState("file"); // State to toggle input type
  const { profiles } = useContext(DataContext);
  const [senderPhoneNumber, setSenderPhoneNumber] = useState(null);
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [messages, setMessages] = useState([]);
  const [showMessages, setShowMessages] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [customerName, setCustomerName] = useState(null);
  const [sendMessageText, setSendMessageText] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const send = async () => {
    try {
      const submissionData = new FormData();
      submissionData.append("phoneNumber", phoneNumber);
      submissionData.append("accessToken", accessToken);
      submissionData.append("instanceId", instanceId);
      submissionData.append("message", message);
      submissionData.append("name", name);

      if (inputType === "file" && file) {
        submissionData.append("file", file); // Append file if file input is selected
      } else if (inputType === "url" && imageUrl) {
        submissionData.append("imageUrl", imageUrl); // Append image URL if URL input is selected
      }

      const response = await fetch("https://certc.in:8443/api/sendmessage", {
        method: "POST",
        body: submissionData,
      });

      const data = await response.json();
      if (response.status === 200) {
        toast.info("Message Sent Successfully", {
          position: "top-right",
          autoClose: 1000, // Close after 3 seconds
        });
      } else {
        alert(data.message);
      }
    } catch (e) {
      console.log(e);
      alert("Error in sending message");
    }
  };

  useEffect(() => {
    if (profiles.length > 0) {
      const temp = profiles.filter((profile) => profile.logoutFromUi === false);
      setTempProfiles(temp);
      setIsProfile(true);
    }
  }, [profiles]);

  const handleProfileChange = (e) => {
    const selectedProfile = tempProfiles.find(
      (profile) => profile.instanceId === e.target.value
    );
    if (selectedProfile) {
      setAccessToken(selectedProfile.accessToken);
      setInstanceId(selectedProfile.instanceId);
      setSenderPhoneNumber(selectedProfile.phoneNumber);
      getChatContacts(selectedProfile.phoneNumber, selectedProfile.accessToken);
    } else {
      setShowContacts(false);
      setShowMessages(false);
    }
  };

  const handleOnContactClick = (key) => {
    getMessages(senderPhoneNumber, contacts[key].customerPhoneNumber);
    setCustomerName(contacts[key].customerName);
    setCustomerPhoneNumber(contacts[key].customerPhoneNumber);
  };

  const getChatContacts = async (phoneNumber, accessToken) => {
    const response = await fetch(
      `https://certc.in:8443/api/getchatcontacts?phoneNumber=${phoneNumber}&accessToken=${accessToken}`
    );
    const data = await response.json();
    if (data.message === "Contacts found") {
      setContacts(data.data);
      setShowContacts(true);
    } else {
      setShowContacts(false);
      setShowMessages(false);
    }
  };

  const getMessages = async (phoneNumber, customerPhoneNumber) => {
    const response = await fetch(
      `https://certc.in:8443/api/getmessages?phoneNumber=${phoneNumber}&customerPhoneNumber=${customerPhoneNumber}`
    );
    const data = await response.json();
    if (data.message === "Chat found") {
      setMessages(data.data);
      setShowContacts(false);
      setShowMessages(true);
    }
  };

  const handleBack = async () => {
    setShowMessages(false);
    setShowContacts(true);
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      const submissionData = new FormData();

      submissionData.append("phoneNumber", customerPhoneNumber);
      submissionData.append("accessToken", accessToken);
      submissionData.append("instanceId", instanceId);
      submissionData.append("message", sendMessageText);
      try {
        const response = await fetch("https://certc.in:8443/api/sendmessage", {
          method: "POST",
          body: submissionData,
        });

        const data = await response.json();
        if (response.status === 200) {
          toast.info("Message Sent Successfully", {
            position: "top-right",
            autoClose: 1000, // Close after 3 seconds
          });
          setSendMessageText("");
          const tempMessages = messages;
          tempMessages.push({ message: sendMessageText, from: "user" });
          setMessages(tempMessages);
        } else {
          alert(data.message);
        }
      } catch (e) {
        console.log(e);
        alert("Error in sending message");
      }
    }
  };

  return (
    <div className={styles.sendmediaContainer}>
      {isProfile ? (
        <>
          <h2 className="heading">Select a Profile</h2>
          <div className={styles.profilesContainer}>
            <select
              className={styles.profileDropdown}
              onChange={handleProfileChange}
            >
              <option value="">Select a Profile</option>
              {tempProfiles.map((profile, key) => (
                <option key={key} value={profile.instanceId}>
                  {profile.name}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.container}>
            <div className={styles.formContainer}>
              <input
                type="text"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Enter Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <textarea
                placeholder="Enter Message"
                value={message}
                style={{
                  maxWidth: "320px",
                  width: "320px",
                  minHeight: "150px",
                  fontSize: "15px",
                }}
                onChange={(e) => setMessage(e.target.value)}
              />

              <div className="input-type-switch">
                <label>
                  <input
                    type="radio"
                    value="file"
                    checked={inputType === "file"}
                    onChange={() => setInputType("file")}
                  />
                  Upload File
                </label>
                <label>
                  <input
                    type="radio"
                    value="url"
                    checked={inputType === "url"}
                    onChange={() => setInputType("url")}
                  />
                  Use Image URL
                </label>
              </div>

              {inputType === "file" ? (
                <input type="file" onChange={handleFileChange} />
              ) : (
                <input
                  type="text"
                  placeholder="Enter Image URL"
                  value={imageUrl}
                  onChange={(e) => setImageUrl(e.target.value)}
                />
              )}

              <button className="submit-button" onClick={send}>
                Send Media
              </button>
            </div>
            {contacts && contacts.length > 0 && showContacts && (
              <div className={styles.contacts}>
                <div style={{ margin: "10px" }}>Recent Chat Contacts</div>
                {contacts.map((contact, key) => (
                  <div
                    className={styles.contact}
                    onClick={() => handleOnContactClick(key)}
                  >
                    <div className={styles.customerName}>
                      {contact.customerName ? contact.customerName : "No Name"}
                    </div>
                    <div>{contact.customerPhoneNumber}</div>
                  </div>
                ))}
              </div>
            )}
            {showMessages && (
              <div className={styles.messagesCon}>
                <div className={styles.backArrow} onClick={() => handleBack()}>
                  <div>👈🏻</div>
                  <div style={{ margin: "0 auto" }}>
                    {customerName ? customerName : "No Name"}
                  </div>
                </div>
                <div className={styles.messageList}>
                  {messages.map((message, index) =>
                    message.from === "user" ? (
                      <div
                        key={index}
                        className={`${styles.message} ${styles.right}`}
                      >
                        <div className={styles.content}>{message.message}</div>
                      </div>
                    ) : (
                      <div key={index} className={styles.message}>
                        <div className={styles.content}>{message.message}</div>
                      </div>
                    )
                  )}
                </div>
                <div className={styles.sendMessage}>
                  <input
                    type="text"
                    placeholder="Enter Message"
                    value={sendMessageText}
                    onChange={(e) => setSendMessageText(e.target.value)}
                    onKeyDown={handleKeyPress}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <h3 className="no-profile">No profile available, please login first</h3>
      )}
      <ToastContainer />
    </div>
  );
};

export default SendMedia;
