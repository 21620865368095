import { useState } from "react";
import "./login.css";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false); // New state for loading spinner

  const handleCheckBox = (e) => {
    setIsChecked(e.target.checked);
  };

  const postLogin = async () => {
    if (!username || !password) {
      alert("Enter username and password");
      return;
    }

    setLoading(true); // Show the spinner

    try {
      const response = await fetch("https://certc.in:8443/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      });

      setLoading(false);

      if (response.status === 200) {
        const data = await response.json();
        const Token = data.user.accessToken;
        if (isChecked === true) {
          localStorage.setItem("token", Token);
        } else {
          localStorage.setItem("token", Token);
        }
        navigate("/whatsapp");
      } else if (response.status === 404) {
        alert("Incorrect username or password");
      }
    } catch (e) {
      setLoading(false);
      alert(`Error in Login: ${e}`);
    }
  };

  return (
    <div className="login">
      {loading && <div className="spinner"></div>} {/* Loading spinner */}
      {!loading && (
        <>
          <h2>Login</h2>
          <div>Sign in to Your Account</div>

          <input
            type="text"
            id="username"
            placeholder="Enter Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            id="password"
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input type="button" value="Submit" onClick={postLogin} />

          <div className="rememberMeCon">
            <input
              type="checkbox"
              name="remember me"
              id="checkbox"
              onChange={handleCheckBox}
            />
            <div>Remember Me</div>
          </div>
        </>
      )}
    </div>
  );
};

export default Login;
